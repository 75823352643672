<template>
  <div>
    <div class="row mx-0 pt-3">
      <div class="col-md-12 pl-0 row mx-0">
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Job Seeker</span
          >
        </div>
        <!-- <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div> -->
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                {{ props.item.name }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                {{ props.item.email }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.block == '' + 1 ? false : true"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Deactivated" : "Activated" }}
              </td>
              <!-- code for verified-unverified -->
              <td>
                <toggle-button
                  :value="props.item.block == '' + 1 ? false : true"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeVerification(props.item)"
                />
                {{ props.item.status ? "Unverified" : "Verified " }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                <span v-if="props.item.mobile">{{ props.item.mobile }}</span>
                <span v-if="!props.item.mobile">Empaty</span>
              </td>
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                <span v-if="props.item.address">{{ props.item.address }}</span>
                <span v-if="!props.item.address">Empaty</span>
              </td>
              <td>
                <!-- <v-btn
                  class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn> -->
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="deleteButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>

          <!-- <template v-slot:top>
            <v-btn
              class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="jobFunction"
            :rules="nameRules"
            label="Job function name"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="createJobFunction"
          >
            Save
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="jobFunction"
            :rules="nameRules"
            label="Job function name"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="updateJobFunction"
          >
            Update
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      jobFunction: "",
      job_function_id: null,
      nameRules: [
        (v) => !!v || "Job function is required",
        (v) =>
          (v && v.length <= 20) ||
          "Job function must be less than 20 characters",
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        //code for verified-unverified
        { text: "Verification", value: "Verification" },
        { text: "Mobile", value: "mobile" },
        { text: "Address", value: "address" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.jobFunction = "";
    },
    createFormEnable() {
      this.jobFunction = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/get-users`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createJobFunction() {
      if (this.jobFunction == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/job-function`, {
          job_function_name: this.jobFunction,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.jobFunction = "";
          Swal.fire({
            title: "",
            text: "Job function added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateJobFunction() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/job-function/${this.job_function_id}`, {
          job_function_name: this.jobFunction,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.jobFunction = "";
          this.job_function_id = "";
          Swal.fire({
            title: "",
            text: "Job function updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/update-user-status/${item.id}`, {
          block: item.block,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeVerification(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/verified-unverified/${item.id}`, {
          block: item.block,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.jobFunction = item.job_function_name;
      this.job_function_id = item.id;
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/delete-user/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "User deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
